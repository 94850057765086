<template>
  <div class="draw">
    <Header callBack @click="goBack" isNoBg="transparent" theme="dark"></Header>
    <div class="container">
      <div class="line"><i class="left"></i>进行中<i class="right"></i></div>
      <div class="text">
        <img src="../../assets/images/draw/text.png" alt="" />
      </div>
      <div class="tip">(多邀请好友可增加中签几率)</div>
      <div class="info">
        <div class="title">参与规则</div>
        <div class="content" v-html="data.rule"></div>
        <!-- <p>1.在全民抽签活动中每位实名用户点击‘参与抽签’即可 获得1个抽签码。</p>
        <p>
          2.活动时间内，每邀请1位新用户完成实名认证，您即
          可额外获得1个抽奖码，每人最多获得5个。
        </p>
        <p>
          3.用户需在开签时间内前往本页面进行开签，本次将会
          有500个中签名额（中签用户可拥有《XXXX》购买权）
        </p>
        <p>4.参与抽签时间：2023-08-21 12:00:00至2023-08- 21 15:00:00</p>
        <p>5.开签时间：2023-08-21 17:00</p> -->
      </div>
      <div class="onwer">我拥有的抽签码：{{ data.my_draw || 0 }}个</div>
      <div class="btns">
        <div class="btn1" v-if="data.is_open === 0">未开始</div>
        <div
          class="btn1"
          @click="userRunDraw"
          v-else-if="data.is_open === 1 && !data.user_is_draw"
        >
          参与抽签
        </div>
        <div
          class="btn1"
          @click="getWinCodeList(1)"
          v-else-if="data.is_open === 1 && data.user_is_draw"
        >
          我的抽签
        </div>
        <div
          class="btn1"
          @click="getWinCodeList(1)"
          v-else-if="data.is_open === 2 && !data.status"
        >
          我的抽签
        </div>
        <div
          class="btn1"
          @click="getWinCodeList(2)"
          v-else-if="data.is_open === 2 && data.status"
        >
          查看结果
        </div>
        <div class="btn2" @click="yaoqing">邀请好友抽签</div>
      </div>
      <div class="list">
        <div class="border"></div>
        <div class="content">
          <div class="title">邀请明细</div>
          <div class="listItem" v-for="item in invite" :key="item.id">
            <div class="listLeft listChild">
              {{ item.realName || item.name }}
            </div>
            <div class="listRight listChild">{{ item.created_at }}</div>
          </div>
          <div class="noDate" v-if="!invite.length">
            <img src="../../assets/images/draw/nodata.png" alt="" />
            <div>暂无内容</div>
          </div>
        </div>
      </div>
      <!-- 我的抽签吗 -->
      <div class="box" v-if="showwinCodeList">
        <div class="boxContent">
          <div class="title">我的抽签码</div>
          <div class="content">
            <div class="listItem" v-for="item in winCodeList" :key="item">
              {{ item }}
            </div>
          </div>
          <i @click="showwinCodeList = false"></i>
        </div>
      </div>
      <div class="box" v-if="showwinCodeList1">
        <div class="boxContent boxBg" v-if="winCodeList.length">
          <div class="title">抽签结果</div>
          <div class="subtitle">恭喜您已中签，中签码为</div>
          <div class="content">
            <div class="listItem" v-for="item in winCodeList" :key="item">
              {{ item }}
            </div>
          </div>
          <div class="bottom">
            <div class="tips">*获得{{ winCodeList.length || 0 }}次购买机会</div>
            <div class="btn" @click="goTo">去购买</div>
          </div>
          <i @click="showwinCodeList1 = false"></i>
        </div>
        <div class="boxContent" v-else>
          <div class="title">我的抽签码</div>
          <div class="err">很遗憾，未中签</div>
          <i @click="showwinCodeList1 = false"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { addBridgeForAndroidWebView } from "sdbridge-android";
export default {
  name: "draw",
  data() {
    return {
      url: "",
      showwinCodeList: false,
      showwinCodeList1: false,
      winCodeList: [],
      data: {},
      invite: [],
      bridge: null,
      isAndroid:
        navigator.userAgent.indexOf("Android") > -1 ||
        navigator.userAgent.indexOf("Adr") > -1,
      isiOS: !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
    };
  },
  mounted() {
    this.init();
    let bridge = window.WebViewJavascriptBridge;
    if (!bridge) {
      console.log("TypeScript正在尝试挂载");
      addBridgeForAndroidWebView();
      //@ts-ignore
      bridge = window.WebViewJavascriptBridge;
      if (bridge) {
        console.log(
          "window.WebViewJavascriptBridge, 已经被TypeScript挂载成功!"
        );
        this.bridge = bridge;
      }
    } else {
      this.bridge = bridge;
      console.log("window.WebViewJavascriptBridge 挂载成功！！");
    }
  },
  methods: {
    goBack() {
      if (this.$route.query.platform) {
        if (this.isAndroid) {
          // eslint-disable-next-line no-undef
          control.jumptoapp(JSON.stringify({ key: -2 }));
        } else {
          this.bridge.callHandler(
            "jumptoapp",
            { key: -2 },
            function (response) {
              console.log(JSON.stringify(response));
            }
          );
        }
      } else {
        this.$router.back();
      }
    },
    yaoqing() {
      if (this.$route.query.platform) {
        if (this.isAndroid) {
          // eslint-disable-next-line no-undef
          control.jumptoapp(JSON.stringify({ key: 31 }));
        } else {
          this.bridge.callHandler(
            "jumptoapp",
            { key: 31 },
            function (response) {
              console.log(JSON.stringify(response));
            }
          );
        }
      } else {
        //新建一个文本框
        let oInput = document.createElement("input");
        //赋值给文本框
        oInput.value = this.url;
        document.body.appendChild(oInput);
        // 选择对象;
        oInput.select();
        // 执行浏览器复制命令
        document.execCommand("Copy");
        //复制完成删除掉输入框
        oInput.remove();
        this.$toast("复制成功");
      }
    },
    goTo() {
      this.$router.push(`/particulars?id=${this.data.id}&add=0`);
    },
    init() {
      this.getDrawInfo();
      this.myInvite();
      this.geUserDraw();
    },
    geUserDraw() {
      const query = {
        id: this.$route.query.id,
        token: this.$route.query.token,
      };
      this.$api.geUserDraw(query).then((e) => {
        this.url = e.data.url;
      });
    },
    getWinCodeList(num) {
      const query = {
        id: this.data.id,
        token: this.$route.query.token,
      };
      this.$api.winCodeList(query).then((e) => {
        this.winCodeList = e.data;
        if (num == 1) {
          this.showwinCodeList = true;
        } else {
          this.showwinCodeList1 = true;
        }
      });
    },
    userRunDraw() {
      const query = {
        id: this.data.id,
        token: this.$route.query.token,
      };
      this.$api.userRunDraw(query).then((e) => {
        this.$toast(e.msg);
        this.getDrawInfo();
      });
    },
    myInvite() {
      const query = {
        id: this.$route.query.id,
        token: this.$route.query.token,
        pageindex: 200,
        pagesize: 1,
      };
      this.$api.myInvite(query).then((e) => {
        if (e.code == 0) {
          this.invite = e.data;
        }
      });
    },
    getDrawInfo() {
      const query = {
        id: this.$route.query.id,
        token: this.$route.query.token,
      };
      this.$api.getDrawInfo(query).then((e) => {
        if (e.code == 0) {
          this.data = e.data;
        } else {
          this.$toast(e.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.draw {
  background: url(../../assets/images/draw/bg.png) no-repeat;
  background-size: cover;
  .container {
    padding: 0 15px 20px;
  }
  .line {
    text-align: center;
    font-size: 15px;
    font-family: PingFang SC;
    color: #fffae3;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .left {
      position: relative;
      width: 25%;
      margin-right: 20px;
      &::before {
        width: 100%;
        height: 1px;
        background: #fffae3;
        display: block;
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      &::after {
        display: block;
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: #fffae3;
      }
    }
    .right {
      position: relative;
      width: 25%;
      margin-left: 20px;
      &::before {
        width: 100%;
        height: 1px;
        background: #fffae3;
        display: block;
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      &::after {
        display: block;
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: #fffae3;
      }
    }
  }
  .text {
    width: 50%;
    margin: 15px auto 0;
    img {
      max-width: 100%;
    }
  }
  .tip {
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #fffae3;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .info {
    width: 100%;
    margin: 0 auto;
    background: #fffbe7;
    border: 5px solid #ff7438;
    box-shadow: 0px 2px 4px 0px rgba(222, 59, 12, 0.49);
    border-radius: 5px;
    padding: 15px 15px 20px;
    box-sizing: border-box;
    margin: 12px 0 14px;
    .title {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #ff7039;
      background: url(../../assets/images/draw/border.png) no-repeat center;
      background-size: cover;
      margin: 0 auto;
      width: 122px;
      height: 23px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 14px;
    }
    p,
    .content {
      font-size: 13px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #6e6d67;
      line-height: 20px;
    }
  }
  .onwer {
    font-size: 13px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #fa5703;
  }
  .btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0 17px;
    .btn1 {
      width: 144px;
      height: 44px;
      background: url(../../assets/images/draw/button1.png) no-repeat center;
      background-size: cover;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      color: #fbc980;
    }
    .btn2 {
      width: 171px;
      height: 44px;
      background: url(../../assets/images/draw/button2.png) no-repeat center;
      background-size: cover;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      color: #fbc980;
    }
  }
  .list {
    .border {
      width: 100%;
      height: 15px;
      background: linear-gradient(0deg, #ffb03c 0%, #fd6200 100%);
      box-shadow: 0px 2px 4px 0px rgba(222, 59, 12, 0.49);
      border-radius: 7px;
    }
    .content {
      width: 90%;
      height: 426px;
      overflow: auto;
      background: #fffbe7;
      box-shadow: 0px -3px 4px 0px rgba(222, 59, 12, 0.49);
      border-radius: 0px 0px 5px 10px;
      margin: 0 auto;
      .title {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #423f32;
        padding-top: 15px;
        text-align: center;
      }
      .listItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 20px;
        .listChild {
          font-size: 13px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #423f32;
        }
      }
    }
  }
  .noDate {
    text-align: center;
    margin-top: 100px;
    img {
      width: 70px;
    }
    div {
      font-size: 13px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #a9a9a9;
    }
  }
  .box {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.6);
    .boxContent {
      width: 80%;
      height: 213px;
      border: 5px solid #f5e2b9;
      background: linear-gradient(19deg, #fcf8e4 0%, #ffffff 100%);
      border-radius: 20px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      .err {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #80867f;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 50px;
      }
      i {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        margin-top: 20px;
        width: 20px;
        height: 20px;
        border: 1px solid #fff;
        border-radius: 50%;
        &::before {
          position: absolute;
          content: "";
          display: block;
          height: 1px;
          width: 80%;
          background: #fff;
          transform: translate(-50%, -50%);
          left: 50%;
          top: 50%;
        }
        &::after {
          transform: translate(-50%, -50%);
          left: 50%;
          top: 50%;
          position: absolute;
          content: "";
          display: block;
          width: 1px;
          height: 80%;
          background: #fff;
        }
      }
      .title {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #2b2e2a;
        text-align: center;
        margin: 16px 0 10px;
      }
      .content {
        flex: 1;
        overflow: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 5px;
        flex-wrap: wrap;
        box-sizing: border-box;
        .listItem {
          margin: 10px 0;
          width: 140px;
          height: 53px;
          font-size: 19px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #b82514;
          background: url(../../assets/images/draw/codebg.png) no-repeat center;
          background-size: contain;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .boxBg {
      background: url(../../assets/images/draw/boxBg.png) no-repeat;
      background-size: cover;
      border: none;
      height: 312px;
      .subtitle {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #80867f;
        text-align: center;
      }
      .content {
        height: 56px;
      }
      .bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        height: 110px;
        .tips {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #fdefe2;
        }
        .btn {
          width: 127px;
          height: 44px;
          background: linear-gradient(111deg, #fef5ed 0%, #f7c999 100%);
          border-radius: 22px;
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #9e2811;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
</style>